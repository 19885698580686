<template>
  <el-row class="form-container">
    <el-row class="resume-content">
      <span class="item-title">Informações cadastrais do colaborador</span>
      <div class="justify-center">
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Nome:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.collaborator.name
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">CPF:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.collaborator.cpf
            }}</span>
          </div>
        </el-col>
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Admissão:</label
            ><span class="item-inner">{{
              handleFormatDate(getCollaboratorRegister.collaborator.admission)
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">Cargo:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.collaborator.role
            }}</span>
          </div>
        </el-col>
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Matrícula:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.collaborator.register
            }}</span>
          </div>
        </el-col>
      </div>
    </el-row>

    <el-row class="resume-content">
      <span class="item-title">Informações de RM do colaborador</span>
      <div class="justify-center">
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Jornada:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.rm.journey
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">Valor hora extra:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.rm.overTimeValue
            }}</span>
          </div>
        </el-col>
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Quantidade hora extra:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.rm.overTimeQuantity
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">Férias início:</label
            ><span class="item-inner">{{
              handleFormatDate(getCollaboratorRegister.rm.vacationStart)
            }}</span>
          </div>
        </el-col>
        <el-col class="item-column">
          <div>
            <label class="item-title-inner">Férias fim:</label
            ><span class="item-inner">{{
              handleFormatDate(getCollaboratorRegister.rm.vacationEnd)
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">Escolaridade:</label
            ><span class="item-inner">{{
              getCollaboratorRegister.rm.instruction
            }}</span>
          </div>
        </el-col>
      </div>
    </el-row>

    <el-row class="resume-content">
      <span class="item-title">Informações dos Centros de Custo e rateio</span>
      <div class="justify-center-wapper">
        <div
          class="item-column-cc"
          v-for="(item, index) in getCollaboratorRegister.apportions"
          :key="index"
        >
          <div>
            <label class="item-title-inner">Centro de custo:</label
            ><span class="item-inner">{{ item.destiny }}</span>
          </div>
          <div>
            <label class="item-title-inner">Tipo:</label
            ><span class="item-inner">{{
              item.type === 'direct' ? 'Direto' : 'Indireto'
            }}</span>
          </div>
          <div>
            <label class="item-title-inner">Percentual de rateio:</label
            ><span class="item-inner">{{ item.apportion }}%</span>
          </div>
        </div>
      </div>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DisplayStep3',
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(['getCollaboratorRegister']),
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
