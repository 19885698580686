<template>
  <el-form
    ref="formObj"
    :model="formObj"
    label-width="120px"
    label-position="top"
    :rules="getRules"
    class="rule-form-container"
  >
    <el-row class="form-container">
      <el-row class="form-container-inner">
        <el-col>
          <el-form-item label="Jornada (Diária)" prop="journey">
            <el-input
              type="number"
              placeholder="Informe a quantidade da jornada diária do colaborador"
              v-model="formObj.journey"
              min="0"
              max="24"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Férias início" prop="vacationStart">
            <el-date-picker
              format="dd/MM/yyyy"
              class="el-input"
              v-model="formObj.vacationStart"
              type="date"
              placeholder="Selecione a data do início das férias"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-container-inner">
        <el-col>
          <el-form-item label="Valor hora extra" prop="overTimeValue">
            <Money
              class="my-margin el-input__inner"
              v-bind="money"
              v-model="formObj.overTimeValue"
            ></Money>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Férias fim" prop="vacationEnd">
            <el-date-picker
              format="dd/MM/yyyy"
              class="el-input"
              v-model="formObj.vacationEnd"
              type="date"
              placeholder="Selecione a data do fim das férias"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-container-inner">
        <el-col>
          <el-form-item label="Quantidade hora extra" prop="overTimeQuantity">
            <el-input type="number" v-model="formObj.overTimeQuantity" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Escolaridade" prop="instruction">
            <el-select
              class="el-input"
              v-model="formObj.instruction"
              placeholder="Selecione a escolaridade do colaborador"
            >
              <el-option
                v-for="item in schoolingOptions"
                :key="`select-instruction-${item}`"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-row>
  </el-form>
</template>

<script>
import { fetchSchooling } from '@/service/schooling'
import { Money } from 'v-money'
import { mapActions, mapGetters } from 'vuex'
import dayjs from '@/utils/dayjs'

export default {
  name: 'DisplayStep1',
  data() {
    return {
      formObj: {
        journey: '',
        vacationStart: null,
        vacationEnd: null,
        overTimeQuantity: '',
        overTimeValue: '',
        instruction: '',
      },
      schoolingOptions: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
    }
  },
  props: {
    title: String,
  },
  components: {
    Money,
  },
  computed: {
    getRules() {
      return {
        journey: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value > 24) {
                callback(new Error('Jornada não pode ser maior que 24 horas'))
              } else if (value < 0) {
                callback(new Error('Jornada não pode ser negativo'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        vacationStart: [
          {
            required: false,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              const dateAdmission = dayjs(
                this.getCollaboratorRegister()?.collaborator?.admission || ''
              )
              const dateVacation = dayjs(value)

              if (dateAdmission.isAfter(dateVacation)) {
                callback(
                  new Error(
                    'A data de início das férias não pode vir antes da data de admissão do colaborador.'
                  )
                )
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        vacationEnd: [
          {
            required: false,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              const dateStart = dayjs(this.formObj.vacationStart)
              const dateEnd = dayjs(value)

              if (this.handleIsValidDate(value) && dateStart.isAfter(dateEnd)) {
                callback(
                  new Error(
                    'A data do fim das férias não pode vir antes da data do início das férias.'
                  )
                )
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        overTimeQuantity: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
        overTimeValue: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
        instruction: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
      }
    },
  },
  watch: {
    formObj: {
      handler(newValue) {
        this.updateCollaboratorRegister({
          prop: 'rm',
          newState: {
            ...newValue,
          },
        })
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['updateCollaboratorRegister']),
    ...mapGetters(['getCollaboratorRegister']),

    async fetchSchoolingOptions() {
      try {
        const { data } = await fetchSchooling()
        if (data) {
          this.schoolingOptions = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
  },
  created() {
    window.scrollTo(0, 0)
    this.fetchSchoolingOptions()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
