<template>
  <div>
    <GeneralHeader
      :title="`Novo colaborador ${getCollaboratorRegister.rowInformations.costCenterInformations.projectName}`"
      subtitle="Preencha as informações do novo colaborador"
      class="custom-header"
    />
    <el-row class="container">
      <el-row>
        <el-col class="steps-menu" :span="4">
          <el-steps :active="currentStep" direction="vertical">
            <el-step
              :title="item.stepLabel"
              v-for="(item, index) in stepComponents"
              :key="index"
            />
          </el-steps>
        </el-col>
        <el-col :span="14" class="steps-container">
          <el-row class="container-header">
            <span class="container-title">
              {{ stepComponents[currentStep].pageTitle }}
            </span>
            <span class="container-subtitle">{{
              stepComponents[currentStep].pageSubtitle
            }}</span>
          </el-row>
          <keep-alive>
            <component
              :ref="`myComponentRef-${currentStep}`"
              v-bind:is="stepComponents[currentStep].component"
            />
          </keep-alive>
        </el-col>
      </el-row>
      <el-row class="align-end">
        <el-button
          :disabled="currentStep < 1"
          class="secondary-button"
          @click="backStep()"
          >Voltar</el-button
        >
        <el-button
          v-if="!(currentStep >= stepComponents.length - 1)"
          class="primary-button"
          :disabled="buttonIsDisabled"
          @click="nextStep()"
          >Próximo</el-button
        >
        <el-button v-else class="primary-button" @click="handleSaveData()"
          >Salvar</el-button
        >
      </el-row>
    </el-row>
  </div>
</template>

<script>
import GeneralHeader from '@/components/GeneralHeader/GeneralHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import DisplayStep0 from './partials/DisplayStep0/DisplayStep0.vue'
import DisplayStep1 from './partials/DisplayStep1/DisplayStep1.vue'
import DisplayStep2 from './partials/DisplayStep2/DisplayStep2.vue'
import DisplayStep3 from './partials/DisplayStep3/DisplayStep3.vue'

import { registerCollaborator } from '@/service/collaborator'

export default {
  name: 'NewCollaboratorInRelated',
  data() {
    return {
      buttonIsDisabled: false,
      stepComponents: [
        {
          component: DisplayStep0,
          stepLabel: 'Colaborador',
          pageTitle: 'Adicione informações cadastrais do colaborador',
          pageSubtitle: '',
        },
        {
          component: DisplayStep1,
          stepLabel: 'RM',
          pageTitle: 'Adicione informações de RM do colaborador',
          pageSubtitle: '',
        },
        {
          component: DisplayStep2,
          stepLabel: 'Alocação',
          pageTitle: 'Alocação do colaborador',
          pageSubtitle:
            'Adicione centros de custo de destino do usuário e informe o total de percentual alocado',
        },
        {
          component: DisplayStep3,
          stepLabel: 'Resumo',
          pageTitle: 'Resumo do cadastro',
          pageSubtitle: '',
        },
      ],
      currentStep: 0,
    }
  },
  components: {
    GeneralHeader,
    DisplayStep0,
    DisplayStep1,
    DisplayStep2,
    DisplayStep3,
  },
  computed: {
    ...mapGetters(['getCollaboratorRegister']),
  },
  methods: {
    ...mapActions(['updateNavCurrentIndex', 'updateStoreCollaboratorRegister']),

    async handleSaveData() {
      const loading = this.$loading({
        lock: true,
        text: 'Salvando colaborador...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        await registerCollaborator({
          ...this.getCollaboratorRegister.collaborator,
          ...this.getCollaboratorRegister.rm,
          cpf: this.getCollaboratorRegister.collaborator.cpf
            .replace('.', '')
            .replace('-', ''),
          apportions: [...this.getCollaboratorRegister.apportions],
          costCenterCode:
            this.getCollaboratorRegister.rowInformations.costCenterInformations
              .centerCostCode,
          month:
            this.getCollaboratorRegister.rowInformations.costCenterInformations
              .startDay,
          correlateId: this.getCollaboratorRegister.rowInformations.correlateId,
          ledgerBookId:
            this.getCollaboratorRegister.rowInformations.ledgerBookId,
          rubric: this.getCollaboratorRegister.rowInformations.rubric,
          overTimeQuantity: parseFloat(
            this.getCollaboratorRegister.rm.overTimeQuantity
          ),
          journey: parseFloat(this.getCollaboratorRegister.rm.journey),
          collabValue:
            this.getCollaboratorRegister.rowInformations.collabValues,
        })
        this.$store.collaboratorRegister = {}
        this.$router.go(-1)
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error?.response?.data,
          duration: 2000,
        })
      } finally {
        loading.close()
      }
    },
    nextStep() {
      this.$refs[`myComponentRef-${this.currentStep}`].$refs[
        'formObj'
      ].validate((valid) => {
        if (valid) {
          this.currentStep++
          return true
        } else {
          return false
        }
      })
    },
    backStep() {
      this.currentStep--
    },
  },
  created() {
    this.updateStoreCollaboratorRegister()
    window.scrollTo(0, 0)
    this.updateNavCurrentIndex('projects')
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
