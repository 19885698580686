<template>
  <div>
    <el-form
      ref="formObj"
      :model="formObj"
      label-width="120px"
      label-position="top"
      :rules="rulesFormObj"
      class="rule-form-container"
    >
      <el-row>
        <el-col>
          <el-tooltip
            v-model="tooltipVisibility"
            class="item"
            effect="dark"
            content="Percentual de alocação deve ser igual a 100%"
            placement="left"
          >
            <el-form-item
              label="Total de percentual alocado"
              prop="percentageAllocation"
            >
              <el-progress
                :percentage="formObj.percentageAllocation"
                color="#E87305"
              />
            </el-form-item>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="padding-flex">
          <div class="row-flex">
            <span class="row-title">Alocação por centros de custo</span>
            <el-button
              :disabled="formObj.percentageAllocation >= 100 ? true : false"
              @click="handleAddNewCostCenter()"
              icon="el-icon-plus"
              class="primary-secondary-button"
              >Adicionar centro de custo</el-button
            >
          </div>
          <el-row class="table-container">
            <el-table
              size="small"
              class="cc-table"
              :data="tableData"
              height="100%"
              max-height="100%"
              empty-text="Sem informações"
            >
              <el-table-column
                label="Centro de Custo"
                prop="destiny"
                sortable
              />
              <el-table-column prop="type" label="Tipo de alocação">
              </el-table-column>
              <el-table-column prop="apportion" label="Percentual de rateio">
                <template slot-scope="scope">
                  <span>{{ scope.row.apportion }}%</span>
                </template>
              </el-table-column>
              <el-table-column label="Ações" width="200">
                <template slot-scope="scope">
                  <i
                    class="el-icon-edit column-with-icon-left"
                    @click="handleEdit(scope.row)"
                  ></i>
                  <i
                    class="el-icon-delete-solid column-with-icon-left"
                    @click="handleDelete(scope.$index)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer
      class="drawer"
      :title="getTitle"
      :visible.sync="visibility"
      :size="450"
      direction="rtl"
      :before-close="handleDrawerClose"
      :wrapperClosable="false"
    >
      <el-main class="content-container">
        <el-form
          ref="formObjDrawer"
          :model="formObjDrawer"
          label-width="120px"
          label-position="top"
          :rules="rules"
          class="rule-form-container"
        >
          <el-form-item label="Centro de custo" prop="destiny" v-if="!editMode">
            <el-select
              class="el-input"
              v-model="formObjDrawer.destiny"
              placeholder="Selecione os centros de custo"
            >
              <el-option
                v-for="(item, index) in costCenters"
                :key="`${item.name}${index}`"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Tipo do colaborador"
            prop="type"
            v-if="!editMode"
          >
            <el-select
              class="el-input"
              v-model="formObjDrawer.type"
              placeholder="Selecionar o tipo do colaborador"
            >
              <el-option
                v-for="item in collaboratorOptions"
                :key="`select-${item.value}`"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Percentual de rateio" prop="apportion">
            <el-input-number
              v-model="formObjDrawer.apportion"
              :max="
                formObj.percentageAllocation >= 0
                  ? 100 - Number(formObj.percentageAllocation)
                  : 1
              "
              :min="0.01"
            />
          </el-form-item>
        </el-form>
        <div class="buttons-container">
          <el-button class="secondary-button" @click="handleDrawerClose()">
            Cancelar
          </el-button>
          <el-button class="primary-button" @click="handleAddCostCenter()">{{
            getBtnLabel
          }}</el-button>
        </div>
      </el-main>
    </el-drawer>
  </div>
</template>

<script>
import { fetchGetCostCenters } from '@/service/costCenters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DisplayStep2',
  data() {
    return {
      tableData: [],
      tooltipVisibility: true,
      visibility: false,
      editMode: false,
      costCenters: [],
      formObjDrawer: {
        apportion: 0,
        type: '',
        destiny: '',
      },
      currentRow: null,
      formObj: { percentageAllocation: 0 },
      collaboratorOptions: [
        { name: 'Direto', value: 'direct' },
        { name: 'Indireto', value: 'indirect' },
      ],
      rulesFormObj: {
        percentageAllocation: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === 0) {
                callback(
                  new Error(
                    'Adicione centros de custo para somar o percentual!'
                  )
                )
              } else if (value > 0 && value < 100) {
                callback(
                  new Error('Percentual de alocação deve ser igual a 100%')
                )
                callback()
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
      rules: {
        apportion: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
        destiny: [
          {
            required: true,
            message: 'Campo é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  props: {
    title: String,
  },
  watch: {
    tableData() {
      this.updateCollaboratorRegister({
        prop: 'apportions',
        newState: this.tableData,
      })
    },
  },
  computed: {
    ...mapGetters(['getCollaboratorRegister']),

    getTitle() {
      return this.editMode
        ? 'Atualizar centro de custo'
        : 'Adicionar centro de custo'
    },
    getBtnLabel() {
      return this.editMode ? 'Atualizar' : 'Adicionar'
    },
  },
  methods: {
    ...mapActions(['updateCollaboratorRegister']),

    calculatePercentage() {
      this.formObj.percentageAllocation = this.tableData.reduce(
        (acc, elem) => acc + elem.apportion,
        0
      )
    },
    handleAddNewCostCenter() {
      this.formObjDrawer.apportion =
        100 - Number(this.formObj.percentageAllocation)
      this.visibility = true
    },
    handleEdit(row) {
      this.currentRow = row
      this.editMode = true
      this.visibility = true
    },
    handleDelete(index) {
      this.tableData.splice(index, 1)
      this.calculatePercentage()
    },
    handleDrawerClose() {
      this.formObjDrawer.apportion =
        100 - Number(this.formObj.percentageAllocation)
      this.formObjDrawer.type = ''
      this.formObjDrawer.destiny = ''
      this.visibility = false
      this.editMode = false
    },
    handleAddCostCenter() {
      this.$refs['formObjDrawer'].validate((valid) => {
        if (valid) {
          if (this.editMode) {
            this.currentRow.apportion = this.formObjDrawer.apportion
          } else {
            this.tableData.push({ ...this.formObjDrawer })
          }
          this.calculatePercentage()
          this.handleDrawerClose()
        } else {
          return false
        }
      })
    },
    async handleGetCostCenters() {
      const sendDate =
        this.getCollaboratorRegister.rowInformations.costCenterInformations
          .startDay
      try {
        const { data } = await fetchGetCostCenters(sendDate)
        if (data) {
          this.costCenters = data
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
  },
  created() {
    window.scrollTo(0, 0)
    this.handleGetCostCenters()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
