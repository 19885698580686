<template>
  <div class="header-info-container">
    <el-button
      icon="el-icon-arrow-left"
      id="return-button"
      @click="handleReturn"
    ></el-button>
    <div class="info-container">
      <span>
        {{ title }}
      </span>
      <span class="info-container-date">
        {{ subtitle }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralHeader',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    subtitle: {
      type: String,
      require: true,
    },
  },
  methods: {
    handleReturn() {
      this.$router.go(-1)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
