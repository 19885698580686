<template>
  <el-form
    ref="formObj"
    :model="formObj"
    label-width="120px"
    label-position="top"
    :rules="rules"
    class="rule-form-container"
  >
    <el-row class="form-container">
      <el-row class="form-container-inner">
        <el-col>
          <el-form-item label="Nome" prop="name">
            <el-input
              type="text"
              placeholder="Adicione o nome do colaborador"
              :maxlength="250"
              show-word-limit
              v-model="formObj.name"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Admissão" prop="admission">
            <el-date-picker
              format="dd/MM/yyyy"
              class="el-input"
              v-model="formObj.admission"
              type="date"
              placeholder="Selecione a data de admissão"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-container-inner">
        <el-col>
          <el-form-item label="Matrícula" prop="register">
            <el-input
              type="number"
              placeholder="Informe a matrícula"
              :maxlength="250"
              show-word-limit
              v-model="formObj.register"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Cargo" prop="role">
            <el-input
              type="text"
              placeholder="Informe a função do colaborador"
              :maxlength="250"
              show-word-limit
              v-model="formObj.role"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-container-inner">
        <el-col class="input-width">
          <el-form-item label="CPF" prop="cpf" v-mask="mask">
            <el-input
              type="text"
              placeholder="Informe o CPF do colaborador"
              v-model="formObj.cpf"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DisplayStep0',
  data() {
    return {
      formObj: {
        name: '',
        register: '',
        admission: null,
        role: '',
        cpf: '',
      },
      mask: '###.###.###-#',
      rules: {
        name: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 5,
            message: 'Precisa ter pelo menos 5 caracteres',
            trigger: 'blur',
          },
        ],
        register: [
          {
            required: true,
            message: 'Matrícula é obrigatório',
            trigger: 'blur',
          },
          {
            min: 4,
            message: 'Precisa ter pelo menos 4 caracteres',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value.lenght > 250) {
                callback(
                  new Error('Matrícula não pode ter mais de 250 caracteres')
                )
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        admission: [
          {
            required: true,
            message: 'Admissão é obrigatório',
            trigger: 'blur',
          },
        ],
        role: [
          {
            required: true,
            message: 'Cargo é obrigatório',
            trigger: 'blur',
          },
        ],
        cpf: [
          {
            required: true,
            message: 'CPF é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  props: {
    title: String,
  },
  watch: {
    formObj: {
      handler(newValue) {
        this.updateCollaboratorRegister({
          prop: 'collaborator',
          newState: {
            ...newValue,
          },
        })
      },
      deep: true,
    },
  },
  created() {
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions(['updateCollaboratorRegister']),
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
